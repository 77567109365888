import _ from 'lodash';
import moment from 'moment';

import { Id, IntervalTypes, OpconQueryPayload, PerformQueryPayload, PerformSegmentBy } from '../../types';

type DefaultArguments = {
    start: Date;
    end: Date;
    driverIds?: Id[];
    vehicleIds: Id[];
};

export const TEMPLATE_VEHICLE_REQUEST = ({
    start,
    end,
    vehicleIds,
    segmentBy = PerformSegmentBy.vehicle,
}: DefaultArguments & { segmentBy?: Exclude<PerformSegmentBy, PerformSegmentBy.interval> }) => ({
    from: start,
    to: end,
    attributes: [
        'average_speed',
        'average_weight',
        'co_2_emission',
        'distance_fuel_consumption',
        'driver_ids',
        'driving_consumption',
        'driving_time',
        'fuel_consumption',
        'fuel_efficiency',
        'idling_consumption',
        'idling_time',
        'inaccurate_vehicle_ids',
        'mileage',
        'operating_fuel_consumption',
        'operating_time',
        'result_from',
        'result_to',
        'vehicle_ids',
        'electric_average_idling_consumption',
        'electric_average_driving_consumption',
        'electric_operating_consumption',
        'electric_average_operating_consumption',
        'electric_average_operating_consumption_range',
        'electric_recuperation',
        'electric_efficiency',
        'total_power_consumption',
    ],
    vehicle_ids: vehicleIds,
    segment_by: segmentBy,
    includes: [],
    driver_ids: [],
});

export const DRIVER_REQUEST_BY_TIME_ATTRIBUTES = [
    'acceleration_pedal_count_with_cc',
    'acceleration_pedal_distance_with_cc',
    'acceleration_pedal_rating',
    'acceleration_pedal_time_with_cc',
    'active_coasting_count',
    'active_coasting_distance',
    'active_coasting_time',
    'average_speed',
    'average_weight',
    'braking_rating',
    'coasting_cc_count',
    'coasting_cc_distance',
    'coasting_cc_time',
    'coasting_rating',
    'cruise_control_consumption_in_potential',
    'cruise_control_distance_in_potential',
    'cruise_control_potential_distance',
    'cruise_control_rating',
    'cruise_control_time_in_potential',
    'distance_fuel_consumption',
    'driver_ids',
    'driving_consumption',
    'driving_time',
    'emergency_brake_count',
    'foresighted_driving_percentage',
    'foresighted_driving_percentage_without_cc',
    'fuel_consumption',
    'fuel_efficiency',
    'idling_consumption',
    'inaccurate_vehicle_ids',
    'interval_desc',
    'kickdown_count',
    'kickdown_distance',
    'kickdown_time',
    'mileage',
    'motor_brake_count',
    'motor_brake_distance',
    'motor_brake_time',
    'no_cruise_control_consumption_in_potential',
    'no_cruise_control_distance_in_potential',
    'no_cruise_control_time_in_potential',
    'operating_fuel_consumption',
    'operating_time',
    'overspeed_distance',
    'overspeed_rating',
    'result_from',
    'result_to',
    'retarder_count',
    'retarder_distance',
    'retarder_time',
    'service_brake_count',
    'service_brake_distance',
    'service_brake_time',
    'vehicle_ids',
];

export const ELECTRIC_DRIVER_REQUEST_BY_TIME_ATTRIBUTES = [
    'electric_average_driving_consumption',
    'electric_average_idling_consumption',
    'electric_operating_consumption',
    'electric_average_operating_consumption',
    'electric_average_operating_consumption_range',
    'electric_recuperation',
    'electric_recuperation_per_km',
    'electric_recuperation_km_per_kwh',
    'electric_recuperation_per_hour',
    'electric_efficiency',
    'total_power_consumption',
];

export const V3_RATING_ATTRIBUTES = [
    'acceleration_pedal_rating',
    'cruise_control_rating_v3',
    'foresighted_driving_percentage_v3',
];

export const V2_RATING_ATTRIBUTES = [
    'acceleration_pedal_rating_v2',
    'cruise_control_rating_v2',
    'foresighted_driving_percentage_v2',
];

export const TEMPLATE_DRIVER_REQUEST_BY_TIME = ({
    start,
    end,
    driverIds,
    vehicleIds,
    intervalType = 'day',
    shouldUseV3Ratings,
}: DefaultArguments & { intervalType?: IntervalTypes; shouldUseV3Ratings: boolean }) => ({
    from: start,
    to: end,
    attributes: [
        ...DRIVER_REQUEST_BY_TIME_ATTRIBUTES,
        ...(shouldUseV3Ratings ? V3_RATING_ATTRIBUTES : V2_RATING_ATTRIBUTES),
        'foresighted_driving_percentage_without_cc_v2',
        ...ELECTRIC_DRIVER_REQUEST_BY_TIME_ATTRIBUTES,
    ],
    driver_ids: driverIds,
    vehicle_ids: vehicleIds,
    interval_type: intervalType,
    segment_by: PerformSegmentBy.interval,
    includes: ['total_segment'],
});

export const TEMPLATE_DRIVER_ELECTRIC_REQUEST_BY_TIME = ({
    start,
    end,
    driverIds,
    vehicleIds,
    intervalType = 'day',
}: DefaultArguments & { intervalType?: IntervalTypes }) => ({
    from: start,
    to: end,
    attributes: [
        'foresighted_driving_percentage_v3',
        'foresighted_driving_percentage_v2',
        'foresighted_driving_percentage_without_cc_v2',
        ...DRIVER_REQUEST_BY_TIME_ATTRIBUTES,
    ],
    driver_ids: driverIds,
    vehicle_ids: vehicleIds,
    interval_type: intervalType,
    segment_by: PerformSegmentBy.interval,
    includes: ['total_segment'],
});

export const DRIVER_REQUEST_ATTRIBUTES = [
    'acceleration_pedal_count_with_cc',
    'acceleration_pedal_distance_with_cc',
    'acceleration_pedal_rating',
    'acceleration_pedal_time_with_cc',
    'active_coasting_count',
    'active_coasting_distance',
    'active_coasting_time',
    'average_speed',
    'average_weight',
    'braking_rating',
    'coasting_cc_count',
    'coasting_cc_distance',
    'coasting_cc_time',
    'coasting_rating',
    'cruise_control_consumption_in_potential',
    'cruise_control_distance_in_potential',
    'cruise_control_potential_distance',
    'cruise_control_rating',
    'cruise_control_time_in_potential',
    'distance_fuel_consumption',
    'driver_ids',
    'driving_consumption',
    'driving_time',
    'emergency_brake_count',
    'foresighted_driving_percentage',
    'foresighted_driving_percentage_without_cc',
    'fuel_consumption',
    'fuel_efficiency',
    'idling_consumption',
    'inaccurate_vehicle_ids',
    'kickdown_count',
    'kickdown_distance',
    'kickdown_time',
    'mileage',
    'motor_brake_count',
    'motor_brake_distance',
    'motor_brake_time',
    'no_cruise_control_consumption_in_potential',
    'no_cruise_control_distance_in_potential',
    'no_cruise_control_time_in_potential',
    'operating_fuel_consumption',
    'operating_time',
    'overspeed_distance',
    'overspeed_rating',
    'result_from',
    'result_to',
    'retarder_count',
    'retarder_distance',
    'retarder_time',
    'service_brake_count',
    'service_brake_distance',
    'service_brake_time',
    'vehicle_ids',
];

export const TEMPLATE_DRIVER_REQUEST = ({
    start,
    end,
    driverIds,
    vehicleIds,
    segmentBy = PerformSegmentBy.driver,
    shouldUseV3Ratings = false,
}: DefaultArguments & {
    segmentBy?: Exclude<PerformSegmentBy, PerformSegmentBy.interval>;
    shouldUseV3Ratings?: boolean;
}) => ({
    from: moment(start).toDate(),
    to: moment(end).toDate(),
    attributes: [
        ...DRIVER_REQUEST_ATTRIBUTES,
        ...(shouldUseV3Ratings ? V3_RATING_ATTRIBUTES : V2_RATING_ATTRIBUTES),
        'foresighted_driving_percentage_without_cc_v2',
        'overspeed_threshold',
    ],
    driver_ids: driverIds,
    vehicle_ids: vehicleIds,
    segment_by: segmentBy,
    includes: [],
});

export const TEMPLATE_OPCON_REQUEST = ({
    start,
    end,
    vehicleIds = [],
    driverIds = [],
}: Omit<DefaultArguments, 'driverIds'> & { driverIds: (string | null)[] }) =>
    ({
        from: moment(start).toISOString(true),
        to: moment(end).toISOString(true),
        ...(vehicleIds.length ? { asset_ids: vehicleIds } : {}),
        ...(driverIds.length ? { driver_ids: driverIds } : {}),
        facets: ['weight', 'topology', 'range', 'traffic', 'route-type'],
        group_by: [
            driverIds.filter(Boolean).length && 'driver-id',
            vehicleIds.filter(Boolean).length && 'asset-id',
        ].filter(Boolean),
    } as OpconQueryPayload);

export const TEMPLATE_FORESIGHTED_REQUEST = ({
    start,
    end,
    vehicleIds = [],
    driverIds = [],
    segmentBy = PerformSegmentBy.vehicle,
    shouldUseV3Ratings = false,
}: DefaultArguments & {
    segmentBy?: Exclude<PerformSegmentBy, PerformSegmentBy.interval>;
    shouldUseV3Ratings?: boolean;
}) =>
    ({
        from: moment(start).toDate(),
        to: moment(end).toDate(),
        attributes: [
            'acceleration_pedal_count_with_cc',
            'acceleration_pedal_distance_with_cc',
            'acceleration_pedal_rating',
            'acceleration_pedal_time_with_cc',
            'active_coasting_count',
            'active_coasting_distance',
            'active_coasting_time',
            'braking_rating',
            'coasting_rating',
            'cruise_control_consumption_in_potential',
            'cruise_control_distance_in_potential',
            'cruise_control_potential_distance',
            'cruise_control_rating',
            'cruise_control_time_in_potential',
            'driver_ids',
            'emergency_brake_count',
            'foresighted_driving_percentage',
            'foresighted_driving_percentage_without_cc',
            'kickdown_count',
            'kickdown_distance',
            'kickdown_time',
            'motor_brake_count',
            'motor_brake_distance',
            'motor_brake_time',
            'no_cruise_control_consumption_in_potential',
            'no_cruise_control_distance_in_potential',
            'no_cruise_control_time_in_potential',
            'overspeed_distance',
            'overspeed_rating',
            'retarder_count',
            'retarder_distance',
            'retarder_time',
            'service_brake_count',
            'service_brake_distance',
            'service_brake_time',
            'vehicle_ids',
            'coasting_time',
            'coasting_distance',
            'coasting_count',
            'passive_coasting_time',
            'passive_coasting_distance',
            'passive_coasting_count',
            'foresighted_driving_percentage_without_cc_v2',
            ...(shouldUseV3Ratings ? V3_RATING_ATTRIBUTES : V2_RATING_ATTRIBUTES),
            'overspeed_threshold',
        ],
        ...(_.isEmpty(vehicleIds) ? {} : { vehicle_ids: vehicleIds }),
        ...{ driver_ids: driverIds },
        segment_by: segmentBy,
        includes: [],
    } as PerformQueryPayload);

export const TEMPLATE_VEHICLE_OPERATION_IN_DAYS = ({
    start,
    end,
    vehicleIds,
    driverIds,
}: Omit<DefaultArguments, 'driverIds'> & { driverIds: (string | null)[] }) =>
    ({
        from: moment(start).toDate(),
        to: moment(end).toDate(),
        attributes: ['vehicle_ids', 'driver_ids', 'result_from', 'result_to'],
        interval_type: 'day',
        vehicle_ids: vehicleIds,
        driver_ids: driverIds,
        segment_by: PerformSegmentBy.interval,
        includes: [],
    } as PerformQueryPayload);
