import { SelfLoadingVehicleMapProps } from '@features/vehicleMap/types';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { isEqual } from 'lodash';
import React, { lazy, Suspense } from 'react';

const VehicleMap = lazy(() => import('../vehicleMap/SelfLoadingVehicleMap'));

const vehicleMapCache = new Map<string, JSX.Element>();

function createCacheKey(props: SelfLoadingVehicleMapProps): string {
    return JSON.stringify(props);
}

function getCachedVehicleMap(props: SelfLoadingVehicleMapProps): JSX.Element {
    const cacheKey = createCacheKey(props);

    const cachedComponent = vehicleMapCache.get(cacheKey);

    if (cachedComponent) {
        return cachedComponent;
    }

    const newComponent = <VehicleMap {...props} />;
    vehicleMapCache.set(cacheKey, newComponent);
    return newComponent;
}

const VehicleMapWithCache = React.memo((props: SelfLoadingVehicleMapProps) => {
    const cachedComponent = getCachedVehicleMap(props);

    return <Suspense fallback={<Spinner />}>{cachedComponent}</Suspense>;
}, isEqual);

VehicleMapWithCache.displayName = 'VehicleMapWithCache';

export default VehicleMapWithCache;
